:root {
  --dex-spacing-1x: 8px;

  --dex-border-color: var(--core-fill-30-color);
  --dex-border-width: 1px;
  --dex-border-style: solid;
  --dex-border-line: var(--dex-border-width) var(--dex-border-style)
    var(--dex-border-color);
  --dex-border-radius: 6px;

  --dex-top-nav-height: 60px;
}